html, body, #page, .mm-page
{
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
}

body
{
    background-color: white;
    font-family: $font-montserrat;
    font-size: $base-font-size;
    color: $brand-color-dark;
}

a
{
    text-decoration: none;
    color: $brand-color;

    &:hover
    {
        text-decoration: underline;
        color: $brand-color;
    }
}

ul
{
    list-style: none;
}

.mburger
{
    color: #2E97DE;
}

small
{
    font-size: 60%;
}

/**********/
/* Global */
/**********/
h1
{
    font-family: $font-montserrat;
}

h2
{
    font-family: $font-montserrat;
    font-weight: $font-weight-bold;
    color: $brand-color-dark;
    padding-bottom: rem-calc(30);
    font-size: rem-calc(36);
    letter-spacing: 2px;

    @include media-max("sm")
    {
        font-size: rem-calc(29);
    }
}

h3
{
    font-family: $font-montserrat;
    font-weight: $font-weight-bold;
    color: $brand-color-dark;
    padding-bottom: rem-calc(35);
    font-size: rem-calc(20);
    letter-spacing: 2px;
}

h4
{
    font-family: $font-montserrat;
    font-weight: $font-weight-light;
    color: $brand-color-dark;
    font-size: rem-calc(16);
    text-transform: uppercase;
    letter-spacing: 2px;
}

::-moz-selection
{
    background: #2E97DE;
    color: #fff;
}

::selection
{
    background: #2E97DE;
    color: #fff;
}

.margtop20
{
    margin-top: rem-calc(20)
}

.margtop80
{
    margin-top: rem-calc(80);
}

.margbottom50
{
    margin-bottom: rem-calc(50)
}

.block-gradient
{
    background-image: linear-gradient(to bottom left, #2e97de, #4882d9, #686acc, #854cb5, #9b2194);
    color: #fff;

    h2
    {
        color: #fff;
    }
}

.block-gradient-right
{
    background-image: linear-gradient(to bottom right, #2e97de, #4882d9, #686acc, #854cb5, #9b2194);
    color: #fff;

    h2
    {
        color: #fff;
    }

    a
    {
        color: #fff;

        &:hover
        {
            color: #fff;
        }
    }
}

.hr-dashed
{
    border-top: 1px dashed #fff;
    width: rem-calc(200);
}

.icon-box
{
    width: 80px;
    height: 80px;
    color: $brand-color-secondary;
    text-align: center;
    line-height: 80px;
    font-size: 40px;
    border-radius: 50px;
    margin: auto;
    border: 2px solid $brand-color-secondary;
}

.box
{
    background-color: #fff;
    box-shadow: 0 5px 10px -3px hsla(0, 0%, 47%, .3);
    margin-bottom: 2em;
    padding: 30px;
}

form
{
    input, textarea
    {
        border-radius: 5px;
        border: 1px solid #fff;
        background: #fff;
        color: #2A3040;
        padding: 10px;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        height: auto;
    }
}

/**************/
/* Fin Global */
/**************/

#ie-alert
{
    display: none;
    background: #ff0000;
    color: #fff;
    a {
        color: #fff;
        text-decoration: underline;
    }
}

.bd-callout
{
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem;

    h4
    {
        margin-top: 0;
        margin-bottom: .25rem
    }

    p:last-child
    {
        margin-bottom: 0
    }

    code
    {
        border-radius: .25rem
    }

    /*&:focus-within
    {
        background: $brand-color;
        color: #fff;

        .invalid-feedback
        {
            color: #fff;
        }
    }*/

    + .bd-callout
    {
        margin-top: -.25rem
    }
}

.bd-callout-info
{
    border-left-color: #5bc0de;

    &:focus-within
    {
        border-color: #5bc0de;
    }

    h4
    {
        color: #5bc0de
    }
}

.bd-callout-warning
{
    border-left-color: #f0ad4e;

    h4
    {
        color: #f0ad4e
    }
}

.bd-callout-success
{
    border-left-color: #28a745;

    h4
    {
        color: #28a745
    }
}

.bd-callout-danger
{
    border-left-color: #d9534f;

    h4
    {
        color: #d9534f
    }

    input
    {
        border-color: #d9534f !important;
    }

    &:focus-within
    {
        border-color: #d9534f;
        /*label
        {,
            color: #fff;
        }*/
    }

    label
    {
        color: #dc3545;
    }
}

section
{
    padding: rem-calc(70) 0;
}

.widget-service
{
    margin: 0 0 -96px 0;
    padding: 0;
    width: 64px;
    bottom: 50%;
    left: 20px;
    display: block;
    position: fixed;
    overflow: hidden;
    z-index: 100;
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.15), 0 6px 6px -3px rgba(0, 0, 0, 0.05);
    background: $brand-color-secondary;
    border-radius: 2px;

    .ws
    {
        &.ws-callback
        {
            background: #815BB6;
        }

        &.ws-contact
        {
            background: #8E6BBD;
        }

        &.ws-help
        {
            background: $brand-color-secondary;

            i
            {
                padding-left: 6px;
            }
        }

        a
        {
            margin: 0;
            padding: 0;
            width: 64px;
            height: 64px;
            top: 0;
            left: 0;
            display: block;
            position: relative;
            overflow: hidden;
            color: $white;

            &:hover
            {
                i
                {
                    transform: translate3d(0, -8px, 0);
                }

                p
                {
                    transform: translate3d(0, -5px, 0);
                }
            }

            i
            {
                margin: -18px 0 0 -18px;
                padding: 3px;
                font-size: 30px;
                top: 50%;
                left: 50%;
                display: block;
                position: absolute;
                z-index: 10;
                transform: translate3d(0, 0, 0);
                transition: transform .15s;
            }

            p
            {
                margin: 0;
                padding: 0;
                width: 100%;
                bottom: 0;
                display: block;
                position: absolute;
                z-index: 10;
                font: 9px/1em "Open Sans Regular", sans-serif;
                color: #FFF;
                text-align: center;
                transform: translate3d(0, 32px, 0);
                transition: transform .15s;
            }
        }
    }

    @include media-max("sm")
    {
        display: none;
    }
}