@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin fontface($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;
  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );
  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

/*********************/
/*** Media queries ***/
/*********************/

/* from... */
@mixin media-min($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) {
    &{ @content; }
  }
}

/* to... */
@mixin media-max($_key) {
  @media screen and (max-width: map-get($breakpoints, $_key) - 1) {
    &{ @content; }
  }
}

/* from... to... */
@mixin media-between($_keymin, $_keymax) {
  @media screen and (min-width: map-get($breakpoints, $_keymin)) and (max-width: map-get($breakpoints, $_keymax) - 1) {
    &{ @content; }
  }
}

/* at... */
@mixin media($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) and (max-width: map-get($breakpoints, nth(map-keys($breakpoints), index(map-keys($breakpoints), $_key) + 1)) - 1) {
    &{ @content; }
  }
}