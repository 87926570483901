#support
{
    &__title
    {
        background-image: url('../static/pages/background-custumer-client-title.jpg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 121px;
        color: rgba(255, 255, 255, 1);
        line-height: 125px;
        border-radius: 5px;

        h1
        {
            font-weight: lighter;
            font-size: 50px;

            @include media-max("sm")
            {
                font-size: rem-calc(35);
            }
        }
    }

    h2
    {
        font-weight: normal;
        font-size: 30px;
        color: $brand-color-secondary;
    }

    #accordion, #accordion2
    {
        .card
        {
            border-color: $brand-color;
            border-radius: 0;
            border: 0;

            .description
            {
                padding: 10px 0;
            }

            > button
            {
                border-radius: 0;
                padding: 40px 50px 40px 80px;
                background: $brand-color;
                border: 5px solid $brand-color;
                color: #fff;
                text-decoration: none;
                text-align: left;
                line-height: 1;
                min-height: 82px;

                &:hover, &[aria-expanded="true"]
                {
                    background: #fff;
                    color: $brand-color;
                    border: 5px solid $brand-color;
                }

                &:before
                {
                    font-family: 'Icon Uptimyz service client';
                    font-size: 30px;
                    position: absolute;
                    left: 30px;
                    top: 35px;

                    @include media-max("sm")
                    {
                        top: 25px;
                    }
                }

                &:after
                {
                    font-family: 'Font Awesome 5 Free';
                    content: "\f054";
                    position: absolute;
                    right: 40px;
                    top: 44px;

                    @include media-max("sm")
                    {
                        top: 34px;
                    }
                }

                &[aria-expanded="true"]:after
                {
                    content: "\f078";
                }

                @include media-max("sm")
                {
                    padding: 20px 50px 20px 80px;
                }
            }

            #button1
            {
                &:before
                {
                    content: "\e903";
                }
            }

            #button2
            {
                &:before
                {
                    content: "\e900";
                }
            }

            #button3
            {
                &:before
                {
                    content: "\e901";
                }
            }

            #button4
            {
                &:before
                {
                    content: "\e904";
                }
            }

            .card-body
            {
                background: $brand-color-hover;
                border: 0;
                color: $brand-color;

                .card-body
                {
                    padding: 0;
                }
            }
        }
    }

    #accordion2
    {
        #button1
        {
            &:before
            {
                content: "\e90a" !important;
            }
        }

        #button2
        {
            &:before
            {
                content: "\e909" !important;
            }
        }

        #button3
        {
            &:before
            {
                content: "\e908" !important;
            }
        }

        #button4
        {
            padding: 40px 50px 40px 80px;
            &:before
            {
                content: "\e905" !important;
            }

            @include media-max("sm")
            {
                padding: 20px 50px 20px 80px !important;
            }
        }
    }

    #accordion-not-client,
    #accordion-transfert,
    #accordion-receive,
    #accordion-edit-data,
    #accordion-0,
    #accordion-1,
    #accordion-2,
    #accordion-3,
    #accordion-4
    {
        .card
        {
            border-color: $brand-color-hover;
            border-radius: 0;
            border: 0;

            > button
            {
                border-radius: 0;
                padding: 20px 0 20px 30px;
                background: $brand-color-hover;
                border: 0;
                color: $brand-color;
                text-decoration: none;
                text-align: left;
                min-height: auto;

                &:after
                {
                    content: "";
                }

                &[aria-expanded="true"]:after
                {
                    content: "";
                }

                &:before
                {
                    font-family: 'Font Awesome 5 Free';
                    content: "\f054";
                    position: absolute;
                    left: 0;
                    top: 20px;
                    font-size: 1rem;
                }

                &[aria-expanded="true"]:before
                {
                    content: "\f078";
                }

                &:hover, &[aria-expanded="true"]
                {
                    background: $brand-color-hover !important;
                    color: $brand-color;
                    border: 0;
                }
            }

            .card-body
            {
                background: $brand-color-hover;
                border-top: 2px solid #fff;
                color: $brand-color;

                .block-form
                {
                    background-color: $white;
                    padding:10px 20px;

                    h3
                    {
                        border-bottom: 1px solid rgb(240, 242, 247);
                        color: $brand-color;
                        font-weight: 400;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        letter-spacing: initial;
                        margin-bottom: 20px;
                    }

                    form
                    {
                        padding: 0 20px 20px 20px;

                        label
                        {
                            color: $brand-color;
                            margin-bottom: 0;
                        }

                        input:not([type=file]), textarea
                        {
                            border-radius: 0;
                            border:1px solid rgb(227, 232, 240);
                            background-color: rgba(240,242,247,1);
                        }

                        input[type=file]
                        {
                            border:0;
                            padding-top:0;
                            padding-left:0;
                        }

                        button
                        {
                            width: 100%;
                            background-color: $brand-color;
                            color: $white;
                            margin-top: 10px;
                            padding: 10px 0;
                        }
                    }
                }


            }
        }
    }
}

.support
{
    &__box
    {
        position: relative;
        display: flex;
        align-items: center;
        height: auto;
        flex-direction: column;
        padding: 100% 10px 0;
        background: $brand-color;
        color: #fff;
        cursor: pointer;
        transition: all .2s ease-out;

        @include media-max("md")
        {
            margin-bottom: 30px;
        }
    }

    &__inner
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    &__icon
    {
        height: auto;
        width: 100%;
        margin-bottom: 10px;

        .icon-service-client
        {
            font-size: 60px;
            color: #fff;
            font-weight: 100;

            @include media-max("sm")
            {
                font-size: 40px;
            }
        }

        @include media-max("md")
        {
            margin-bottom: 0;
        }
    }

    &__label
    {
        text-align: center;
        flex-grow: 1;
        line-height: 1;
        color: #fff;
    }
}

#accordionFAQ, #accordionFAQ2
{
    .card
    {
        border-color: $brand-color-hover;
        border-radius: 0;
        border-bottom: 1px solid $white;

        > button
        {
            border-radius: 0;
            padding: 20px 0 20px 50px;
            background: $brand-color-hover;
            border: 0;
            color: $brand-color-secondary;
            text-decoration: none;
            text-align: left;

            &:after
            {
                content: "";
            }

            &[aria-expanded="true"]:after
            {
                content: "";
            }

            &:before
            {
                font-family: 'Font Awesome 5 Free';
                content: "\f054";
                position: absolute;
                left: 20px;
                top: 20px;
                font-size: 1rem;
            }

            &[aria-expanded="true"]:before
            {
                content: "\f078";
            }

            &:hover, &[aria-expanded="true"]
            {
                background: $brand-color-hover !important;
                color: $brand-color;
                border: 0;
            }
        }

        .card-body
        {
            background: $brand-color-hover;
            border-top: 2px solid #fff;
            color: $brand-color;
            font-size: 1rem;

            a
            {
                color: $brand-color-secondary;
            }

            ul
            {
                li
                {
                    list-style-type: disc;
                }
            }
        }
    }
}