//base
$base-font-size: 16px;

// Couleur
$brand-color: #2E97DE;
$brand-color-secondary: rgba(117,75,175,1); // #9b2194 //c61f7e //350e90
$brand-color-dark: #424651; //#293032
$brand-color-error: rgb(188, 1, 20);
$brand-color-light: #657884;
$brand-color-hover: #c0eef9;
$white: #fff;

$brand-alptis: #008d3f;
$brand-solly-azar: #F39100;
$brand-wazari: #A9C732;
$brand-ffa: #ffbb00;
$brand-apivia: #004a98;
$brand-acps: #008633;
$brand-asaf: #9DC530;
$brand-eca: #13a25a;
$brand-april: #F19120;
$brand-neoliane: #344b70;
$brand-cegema: #3898d4;
$brand-praeconis: #651e70;
$brand-repam: #455cc7;

// Fonts
$font-montserrat: 'Montserrat', sans-serif;
$font-varela: 'Varela Round', sans-serif;
$font-salt: 'Salt Regular';

// Media queries
/*$breakpoints: (
        "phone-down": 500px,
        "tablet-up": 768px,
        "tablet-down": 900px,
        "desktop-up": 1024px,
        "desktop-down": 1280px,
        "widescreen-up": 1440px
);*/

$breakpoints: (
        "phone-down": 500px,
        "sm": 576px,
        "md": 768px,
        "lg": 992px,
        "xl": 1200px,
        "widescreen-up": 1440px,
        "widescreen-up+": 1558px
);

/****************/
/*** Exemples ***/
/****************/

/*

@include media-min("desktop-up") {
    !* from 1024px to infinite *!
}
@include media-max("tablet-down") {
    !* from 0px to 899px *!
}
@include media-between("tablet-up", "desktop-down") {
    !* from 768px to 1279px *!
}
@include media("tablet-down") {
    !* from 900px to 1023px *!
}

*/

/********************/
/*** Fin Exemples ***/
/********************/