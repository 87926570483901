#comparator
{
    font-size: rem-calc(20);

    :focus {
        outline: 0;
    }

    .logo
    {
        height: 60px;
        width: 100%;
    }

    .container
    {
        box-shadow:8px 0 8px -10px rgba(0,0,0,.2),-8px 0 8px -10px rgba(0,0,0,.2);
        //box-shadow: 0 0 10px rgba(0,0,0,.2);
    }

    #health-mutual
    {
        form
        {
            height: 100vh;
            input
            {
                border: 2px solid #eee;

                &[type=text]
                {
                    height: 40px;
                }
                &:focus
                {
                    border: 2px solid $brand-color;
                    &::placeholder
                    {
                        color: $brand-color;
                    }
                }

                &[type="tel"]
                {
                    + .invalid-feedback
                    {
                        &::before
                        {
                            top: 34%;
                        }
                    }
                }
            }

            label
            {
                cursor: pointer;
            }

            .invalid-feedback
            {
                text-align: center;
                color: white;
                background-color: $brand-color-error;
                padding: 5px 0;
                animation: bounceIn .5s ease;
                border-radius: 0.25rem;
                margin-top: 10px;
                &::before
                {
                    content:'';
                    top: 50%;
                    left: 10%;
                    position: absolute;
                    margin-left: -12px;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $brand-color-error;

                    @include media-max("md")
                    {
                        display: none;
                    }
                }
            }

            #block-accepted .invalid-feedback
            {
                text-align: center;
                color: white;
                background-color: $brand-color-error;
                padding: 5px 0;
                animation: bounceIn .5s ease;
                border-radius: 0.25rem;
                margin-top: 10px;
                &::before
                {
                    display: none;
                }
            }

            .form-control.is-invalid
            {
                &::placeholder
                {
                    color:#dc3545;
                }
            }
        }
    }

    #online-service {
        position: fixed;
        bottom: 30.5px;
        right: 100px;
        height: auto;
        border-radius: 100px;
        border: 0;
        display: flex;
        background-color: $brand-color;
        z-index: 20;

        > a {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 12px;
            margin: 0;
            padding: 20px;

            &:hover
            {
                text-decoration: none;
            }

            @include media-max("md")
            {
                padding: 10px;
            }
        }

        i {
            margin-left: 10px;
            font-size: 20px;

            @include media-max("md")
            {
                margin-left: 0;
            }
        }

        &:after {
            content: "";
            position: absolute;
            background-color: $brand-color;
            bottom: -4px;
            right: 25px;
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
        }

        p {
            margin: 0;
            padding: 0;

            @include media-max("md")
            {
                display: none;
            }
        }

        @include media-max("md")
        {
            padding: 6px 10px;
            right: 20px;
            bottom: 105px;
        }
    }
}