#sponsorship
{
    background: #fff;
    color: $brand-color-dark;
    padding-top: rem-calc(75);
    padding-bottom: rem-calc(75);
    font-size: rem-calc(20);

    .content-top
    {
        text-align: center;
        line-height: rem-calc(130);
        color:$brand-color;
        font-family: $font-family-brand;

        #sponsorship-title
        {
            text-align: left;
            position: relative;
            left: 5px;
            font-size: rem-calc(50);

            @include media-max("md")
            {
                font-size: rem-calc(30);
                line-height: rem-calc(30);
            }
        }

        #sponsorship-amount
        {
            font-size: rem-calc(210);
            color: $brand-color;

            @include media-max("md")
            {
                font-size: rem-calc(80);
                line-height: rem-calc(80);
            }
        }

        #sponsorship-desc
        {
            font-size: rem-calc(27);

            @include media-max("md")
            {
                font-size: rem-calc(17);
                line-height: rem-calc(17);
                margin-bottom: 20px;
            }
        }
    }

    #step
    {
        padding-top: rem-calc(75);
        padding-bottom: rem-calc(75);
    }

    h3
    {
        color: $brand-color-dark;
        font-family: $font-salt;
        font-size: rem-calc(50);
    }

}