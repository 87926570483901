.btn-uptimyz
{
    cursor: pointer;
    padding: 10px 40px;
    font-weight: 700;
    border-radius: 25px;
    font-family: 'Varela Round', sans-serif;
    min-width: 260px;
}

.btn-uptimyz.blue.border2
{
    border: 2px solid $brand-color;
}

.btn-uptimyz.blue
{
    color: $brand-color;
    background-color: #fff;
    border: 1px solid $brand-color;
}

.btn-uptimyz.purple
{
    color: $brand-color-secondary;
    background-color: #fff;
    border: 1px solid $brand-color-secondary;
}

.btn-uptimyz.purple.border3
{
    border: 3px solid $brand-color-secondary;
}

.btn-uptimyz.white
{
    color: #fff;
    background-color: $brand-color;
    border: 1px solid #fff;
}