#content
{
    font-size: rem-calc(20);

    h3
    {
        padding-bottom: 0;
    }

    section:first-child
    {
        position: relative;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        color: #fff;
        font-size: rem-calc(32);
        padding: 0;

        h1
        {
            color: #fff;
            font-weight: 700;
            font-family: $font-varela;
            font-size: rem-calc(50);

            @include media-max("sm")
            {
                font-size: rem-calc(29);
            }
        }

        #subtitle
        {
            @include media-max("sm")
            {
                font-size: rem-calc(20);
            }
        }

        background-image: url('../static/pages/background-content.jpg');

        &#error-bg
        {
            background-image: url('../static/error.jpg');
            background-position:0 -260px;
            padding-top: 150px !important;
        }
    }

    h2
    {
        padding-bottom: rem-calc(10);
        font-weight: 400;
        text-transform: none;
        font-size: rem-calc(24);
    }

    #content-container
    {
        video
        {
            width: 50%;
        }
    }

    #about-us-bottom
    {
        background: url('../static/background-content-blue.jpg') no-repeat center center $brand-color;
        padding-top: rem-calc(75);
        padding-bottom: rem-calc(75);

        li
        {
            float: left;

            a
            {
                color: #fff;
            }

            &:not(:last-of-type) > :first-child:after
            {
                display: inline-block;
                text-decoration: none;
                content: "\2022";
                color: $brand-color-secondary;
                margin-right: 7.6px;
                margin-left: 12px;
                -webkit-transform: translateY(1px);
                transform: translateY(1px);
            }
        }
    }

    form
    {
        input, textarea
        {
            border: 2px solid #ccc;
            width: 100%;
        }
    }

    #form-contact
    {
        min-height: 750px;
    }

    .list
    {
        padding: 0;

        &.list-faq
        {
            li
            {
                background: $brand-color-hover;
                a
                {
                    color: $brand-color-secondary;
                    padding: 20px 80px 20px 50px;
                    font-weight: 400;
                    &:hover
                    {
                        text-decoration: underline;
                    }
                    &:after
                    {
                        right: 50px;
                        @include media-max("lg")
                        {
                            right: 30px;
                        }
                        @include media-max("sm")
                        {
                            right: 10px;
                        }
                    }
                }
            }
        }

        li + li
        {
            border-top: 1px solid #eee;
        }

        a
        {
            color: #505050;
            display: block;
            padding: 20px 40px 20px 0;
            position: relative;
            font-weight: 700;
            text-decoration: none;

            &:after
            {
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-weight: 400;
                font-family: "Font Awesome 5 Free";
                content: "\f0a9";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                font-size: 28px;
            }
        }
    }

    .content__row
    {
        background: #f5f8fa;
        padding: 40px 20px 20px 20px;

        div
        {
            margin-bottom: 20px;

            &:not(:last-child)
            {
                border-right: 2px solid #fff;
            }
        }
    }

    main#lexicon-one
    {
        ul
        {
            li
            {
                list-style-type: disc;
            }
        }
        table
        {
            width: 100%;
            tr
            {
                td, th
                {
                    text-align: center;
                    padding:10px;
                    border: 1px solid $brand-color-dark;
                }
            }
        }
    }
}

.content-bottom
{
    background: url('../static/pages/background-content.jpg') no-repeat center center $brand-color;
    padding-top: rem-calc(75);
    padding-bottom: rem-calc(75);
    color: $white;

    &__title
    {
        font-weight: 100;
        font-size: 40px;

        @include media-max("sm")
        {
            font-size: 30px;
        }
    }

    &__button
    {
        background-color: $white;
        padding: 20px 60px;
        width: 50%;
        display: inline-block;
        text-transform: uppercase;
        color: $brand-color-secondary;

        &:hover
        {
            text-decoration: none;
        }

        @include media-max("sm")
        {
            width: 100%;
        }
    }
}

.gallery
{
    div
    {
        p
        {
            margin-top: 10px;
            font-size: rem-calc(14);
            font-weight: 500;
            color: $brand-color-dark;
        }
    }
}