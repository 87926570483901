$font-family-text: $font-montserrat;
$font-family-brand: $font-varela;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

.text-size-20
{
    font-size: rem-calc(20);
}

