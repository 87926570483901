@-webkit-keyframes arrow
{
    0%
    {
        opacity: 1;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    100%
    {
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(50%);
        transform: translateX(-50%) translateY(50%);
    }
}

@keyframes arrow
{
    0%
    {
        opacity: 1;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    100%
    {
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(50%);
        transform: translateX(-50%) translateY(50%);
    }
}

@-webkit-keyframes appearArrow
{
    0%
    {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        opacity: 0;
    }

    100%
    {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        opacity: 1;
    }
}

@keyframes appearArrow
{
    0%
    {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        opacity: 0;
    }

    100%
    {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        opacity: 1;
    }
}