#content, #support
{
    header
    {
        height: 100px;
        background: #fff;
        -webkit-box-shadow: 0 5px 20px 0 rgba(41, 48, 51, .1);
        box-shadow: 0 5px 20px 0 rgba(41, 48, 51, .1);
        text-transform: uppercase;

        .header-content
        {
            height: 100px;
        }

        .logo-link{
            display: inline-block;
        }
        .logo
        {
            height: 60px;
            margin-top: 20px;
        }

        li
        {
            float:left;
        }

        a.nav-link
        {
            color: $brand-color !important;
            font-weight: 600;
            letter-spacing: 1px;
            margin-top: 10px;
            font-size: 1rem;

            &:not(:last-child)
            {
                margin-right: 50px;
            }

            &:hover
            {
                border-bottom: 3px solid $brand-color-hover;
            }
        }

        &.navbar
        {
            padding-top: 0;

            .navbar-toggler span
            {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(155,33,148, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
            }
        }

        &.navbar-light
        {
            .navbar-toggler
            {
                color: $brand-color-secondary;
                background: #fff !important;
                border: 0;
                border-radius: 0;
            }
        }
    }
}

#home
{
    header
    {
        display: block;
        z-index: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        background-image: url('../static/header-background.png');
        background-repeat: no-repeat;
        background-size: 1024px;
        background-position: top right;
        -webkit-box-shadow: initial;
        box-shadow: initial;

        @include media-max("widescreen-up")
        {
            background-size: 700px;
            height: 600px;
        }

        @include media-max("lg")
        {
            background-size: 500px;
            height: 700px;
        }

        .header-content
        {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: rem-calc(50);
            overflow-y: hidden;
            overflow-x: hidden;

            @include media-max("sm")
            {
                margin-top: rem-calc(20);
            }

            .logo-link{
                display: inline-block;
            }

            .header-content-top
            {
                h1
                {
                    @extend h1;
                    font-weight: $font-weight-bold;
                    color: $brand-color;
                    text-transform: uppercase;
                    font-size: rem-calc(45);

                    span
                    {
                        color: $brand-color-dark;
                        font-size: rem-calc(63);
                        font-family: $font-salt;
                        font-weight: 100;
                        text-transform: lowercase;

                        @include media-max("widescreen-up")
                        {
                            font-size: rem-calc(42);
                        }

                        @include media-between("widescreen-up", "widescreen-up+")
                        {
                            font-size: rem-calc(47);
                        }
                    }

                    @include media-max("widescreen-up")
                    {
                        font-size: rem-calc(30);
                    }

                    @include media-between("widescreen-up", "widescreen-up+")
                    {
                        font-size: rem-calc(34);
                    }
                }

                img.logo, img#logo-white
                {
                    height: 80px;
                }
            }

            .header-content-middle
            {
                margin-top: rem-calc(100);

                @include media-between("lg", "widescreen-up")
                {
                    margin-top: 0;
                }

                @include media-max("sm")
                {
                    margin-top: rem-calc(330);
                }

                .row:first-child
                {
                    margin-bottom: rem-calc(30);
                }

                .block-comparator
                {
                    height: 150px;
                    position: relative;
                    background: $brand-color;
                    color: $white;

                    &:hover
                    {
                        background: $brand-color-hover;
                        color: $brand-color !important;

                        .icon-uptimyz
                        {
                            color: $brand-color !important;
                        }
                    }

                    a
                    {
                        color: $white;
                        text-decoration: none;
                        width: 100%;
                        height: 100%;
                        &:hover
                        {
                            color: $brand-color !important;
                        }
                    }

                    .comparator-content
                    {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        text-align: center;
                        padding-top: 10%;

                        .icon-uptimyz
                        {
                            font-size: rem-calc(32);
                            color: $white;
                        }
                    }
                }

                .block-comparator-small
                {
                    position: relative;
                    max-width: 100%;
                    padding-top: rem-calc(48);
                    background: $white;
                    color: $brand-color;
                    border: 2px solid $brand-color;
                    display: flex;
                    text-decoration: none;

                    &:hover
                    {
                        background: $brand-color-hover;
                    }

                    .comparator-content
                    {
                        div
                        {
                            position: relative;
                            padding: 0 0 0 rem-calc(50);
                        }

                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);

                        .icon-uptimyz
                        {
                            font-size: rem-calc(20);
                            color: $brand-color;
                            left: rem-calc(21);
                            transform: translateY(-50%) translateX(-50%);
                            position: absolute;
                            top: 50%;
                        }
                    }
                }
            }
        }

        nav
        {
            text-transform: uppercase;

            li
            {
                float:left;
            }

            a.nav-link
            {
                color: #fff !important;
                font-weight: 600;
                letter-spacing: 1px;

                &:not(:last-child)
                {
                    margin-right: 50px;
                }

                &:hover
                {
                    border-bottom: 3px solid $brand-color-hover;
                }
            }

            &.navbar
            {
                padding-top: 0;

                .navbar-toggler span
                {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(155,33,148, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
                }
            }

            &.navbar-light
            {
                .navbar-toggler
                {
                    color: $brand-color-secondary;
                    background: #fff !important;
                    border: 0;
                    border-radius: 0;
                }
            }
        }

        #menu-mobile
        {
            position: absolute;
            background: rgba(255, 255, 255, 0.8);
            top: 90px;
            left: 0;
            width: 100%;
            height: 330px;
            z-index: 100;

            a
            {
                color: $brand-color !important;
                padding-left: 10px;
                text-align: center;
            }
        }

        #scroll-down
        {
            margin: 0;
            position: absolute;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            bottom: 50px;
            width: 50px;
            height: 50px;
            background-image: url(../static/arrow-down.png);
            -webkit-animation: appearArrow 1s ease-in-out 2.5s forwards, arrow 1s infinite alternate 0.5s ease-in-out;
            animation: appearArrow 1s ease-in-out 2.5s forwards, arrow 1s infinite alternate 0.5s ease-in-out;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            -webkit-transition: all 1s ease;
            transition: all 1s ease;
            cursor: pointer;
            z-index: 1000;
            border-radius: 50%;

            @include media-max("widescreen-up")
            {
                display: none;
            }
        }
    }
}

.topbar-menu
{
    z-index: 1000;
    height: 100px;
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(41,48,51,.1);
    box-shadow: 0 5px 20px 0 rgba(41,48,51,.1);
    transition: top 0.3s;

    &__content
    {
        padding: 20px;
    }

    text-transform: uppercase;
    li
    {
        float:left;
    }

    a.nav-link
    {
        color: $brand-color !important;
        font-weight: 600;
        letter-spacing: 1px;
        margin-top: 10px;

        &:not(:last-child)
        {
            margin-right: 50px;
        }

        &:hover
        {
            border-bottom: 3px solid $brand-color-hover;
        }
    }

    &.navbar
    {
        padding-top: 0;

        .navbar-toggler span
        {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(155,33,148, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }

    &.navbar-light
    {
        .navbar-toggler
        {
            color: $brand-color-secondary;
            background: #fff !important;
            border: 0;
            border-radius: 0;
        }
    }
}