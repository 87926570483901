@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900|Varela+Round);
@include fontface('Salt Regular', '../fonts/Salt-Regular', null, null, otf ttf);
@include fontface('Icon Uptimyz', '../fonts/icon-uptimyz/fonts/icomoon', null, null, eot ttf woff svg);
@include fontface('Icon Uptimyz service client', '../fonts/icon-service-client/fonts/icomoon', null, null, eot ttf woff svg);

.icon-uptimyz
{
    font-family: 'Icon Uptimyz';

    &.icon-paw::before
    {
        content: "\e900";
    }

    &.icon-pig::before
    {
        content: "\e901";
    }

    &.icon-house::before
    {
        content: "\e902";
    }

    &.icon-mutuelle::before
    {
        content: "\e903";
    }

    &.icon-prevoyance::before
    {
        content: "\e904";
    }
}

.icon-service-client
{
    font-family: 'Icon Uptimyz service client';

    &.icon-transferer::before {
        content: "\e900";
    }

    &.icon-recevoir::before {
        content: "\e901";
    }

    &.icon-contact::before {
        content: "\e902";
    }

    &.icon-inscription::before {
        content: "\e903";
    }

    &.icon-modifier::before {
        content: "\e904";
    }

    &.icon-reclamation::before {
        content: "\e905";
    }

    &.icon-login::before {
        content: "\e906";
    }

    &.icon-partenaire::before {
        content: "\e907";
    }

    &.icon-resilier::before {
        content: "\e908";
    }

    &.icon-cotisation::before {
        content: "\e909";
    }

    &.icon-teletransmission::before {
        content: "\e90a";
    }

    &.icon-faq::before {
        content: "\e90b";
    }
}