.footer
{
    background: #f5f8fa;
    color: $brand-color-dark;
    padding-top: rem-calc(50);
    position: relative;
    z-index: 10;

    &__corner
    {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
    }

    &__content
    {
        margin-bottom: rem-calc(40);

        #social
        {
            width: 100%;
            height: 35px;
            ul
            {
                list-style: none;
                width: 126px;
                margin: 0 auto;
                li
                {
                    float: left;

                    a
                    {
                        border-radius: 3px;
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        text-align: center;
                        margin-right: 7px;
                        text-decoration: none;
                        border: none;
                        color: #ccc;
                        font-size: 20px;

                        &.facebook
                        {
                            background: #3b5998;
                            color: #fff;
                        }

                        &.linkedin
                        {
                            background: #0e76a8;
                            color: #fff;
                        }

                        &.instagram
                        {
                            background: #e95950;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &__bottom
    {
        height: 40px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e97de+0,67e8d9+50,ab1dad+100 */
        background: #2e97de; /* Old browsers */
        background: -moz-linear-gradient(left, #2e97de 0%, #67e8d9 50%, #ab1dad 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #2e97de 0%, #67e8d9 50%, #ab1dad 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #2e97de 0%, #67e8d9 50%, #ab1dad 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e97de', endColorstr='#ab1dad', GradientType=1); /* IE6-9 */
    }

    &__contact-us
    {
        font-size: rem-calc(16);
        h4
        {
            margin-bottom: 20px;
        }
    }

    h2
    {
        color: #fff;
    }

    #contact-us
    {
        margin-bottom: rem-calc(50);
    }

    a
    {
        color: #657883;

        &:hover
        {
            color: $brand-color-secondary;
        }
    }

    ul
    {
        padding: 0;
        margin-top: 20px;
        font-size: rem-calc(16);
    }

    #legal
    {
        margin-top: rem-calc(50);
        color: #657883;
        font-size: 0.9rem;

        .row:first-child
        {
            margin-bottom: 20px;
        }
    }
}