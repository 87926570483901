#partner
{
    &.wazari
    {
        a.external-link
        {
            color: $brand-wazari;
        }

        h2
        {
            color: $brand-wazari;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-wazari;
        }

        h3
        {
            color: $brand-wazari;
        }
    }

    &.ffa
    {
        a.external-link
        {
            color: $brand-ffa;
        }

        h2
        {
            color: $brand-ffa;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-ffa;
        }

        h3
        {
            color: $brand-ffa;
        }
    }

    &.solly-azar
    {
        a.external-link
        {
            color: $brand-solly-azar;
        }

        h2
        {
            color: $brand-solly-azar;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-solly-azar;
        }

        h3
        {
            color: $brand-solly-azar;
        }
    }

    &.alptis
    {
        a.external-link
        {
            color: $brand-alptis;
        }

        h2
        {
            color: $brand-alptis;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-alptis;
        }

        h3
        {
            color: $brand-alptis;
        }
    }

    &.apivia
    {
        a.external-link
        {
            color: $brand-apivia;
        }

        h2
        {
            color: $brand-apivia;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-apivia;
        }

        h3
        {
            color: $brand-apivia;
        }
    }

    &.acps
    {
        a.external-link
        {
            color: $brand-acps;
        }

        h2
        {
            color: $brand-acps;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-acps;
        }

        h3
        {
            color: $brand-acps;
        }
    }

    &.asaf
    {
        a.external-link
        {
            color: $brand-asaf;
        }

        h2
        {
            color: $brand-asaf;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-asaf;
        }

        h3
        {
            color: $brand-asaf;
        }
    }

    &.eca
    {
        a.external-link
        {
            color: $brand-eca;
        }

        h2
        {
            color: $brand-eca;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-eca;
        }

        h3
        {
            color: $brand-eca;
        }
    }

    &.april
    {
        a.external-link
        {
            color: $brand-april;
        }

        h2
        {
            color: $brand-april;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-april;
        }

        h3
        {
            color: $brand-april;
        }
    }

    &.neoliane
    {
        a.external-link
        {
            color: $brand-neoliane;
        }

        h2
        {
            color: $brand-neoliane;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-neoliane;
        }

        h3
        {
            color: $brand-neoliane;
        }
    }

    &.cegema
    {
        a.external-link
        {
            color: $brand-cegema;
        }

        h2
        {
            color: $brand-cegema;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-cegema;
        }

        h3
        {
            color: $brand-cegema;
        }
    }

    &.praeconis
    {
        a.external-link
        {
            color: $brand-praeconis;
        }

        h2
        {
            color: $brand-praeconis;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-praeconis;
        }

        h3
        {
            color: $brand-praeconis;
        }
    }

    &.repam
    {
        a.external-link
        {
            color: $brand-repam;
        }

        h2
        {
            color: $brand-repam;
        }

        .btn-uptimyz.partner
        {
            color: $white;
            background-color: $brand-repam;
        }

        h3
        {
            color: $brand-repam;
        }
    }

    h2
    {
        font-weight: 700;
        text-align: center;
    }

    h3
    {
        font-weight: 400;

        span
        {
            color: #000 !important;
        }
    }

    .content__row.solution
    {
        div
        {
            border: 0 !important;
            margin-bottom: 0;
        }
    }

    .youtube
    {
        max-width: 560px;
        margin: auto;

        div {
            position: relative;
            height: 0;
            padding-bottom: 56.25%;

            iframe
            {
                margin: 0 auto;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-width: none !important;
            }
        }
        @include media-max("lg")
        {
            margin-bottom: 30px;
        }
    }

    ul
    {
        li
        {
            list-style-type: disc;
        }
    }
}