#home
{

    .your-comparison
    {
        .container
        {
            position: relative;
        }
    }

    .mburger
    {
        color: #fff;
    }

    #presentation
    {
        font-size: rem-calc(20);
    }

    /***********************/
    /* QualitÃ© Ã  tout prix */
    /***********************/

    .quality
    {
        background: #f5f8fa;

        &__block
        {
            div
            {
                padding: 2rem;

                &:last-child
                {
                    h3
                    {
                        padding-bottom: 10px;
                    }
                }

                @include media-min("lg")
                {
                    &:not(:nth-child(3)):not(:nth-child(6))
                    {
                        border-right: 2px solid #fff;
                    }

                    &:nth-child(-n+3)
                    {
                        border-bottom: 2px solid #fff;
                    }
                }

                @include media-max("lg")
                {
                    &:not(:nth-child(2)):not(:nth-child(4)):not(:nth-child(6))
                    {
                        border-right: 2px solid #fff;
                    }

                    &:nth-child(-n+4)
                    {
                        border-bottom: 2px solid #fff;
                    }
                }

                @include media-max("md")
                {
                    &:not(:last-child)
                    {
                        border-bottom: 2px solid #fff;
                        border-right: 0 !important;
                    }
                }
            }
        }
    }

    /***************************/
    /* FIN QualitÃ© Ã  tout prix */
    /***************************/

    /***************/
    /* Comparaison */
    /***************/

    .your-comparaison
    {
        position: relative;
        padding: 180px 110px 80px;
        max-width: 1280px;
        margin: 0 auto;

        &__block
        {
            &:hover
            {
                h4
                {
                    border-bottom: 3px solid #9b2194;
                }
            }

            a
            {
                text-decoration: none;
                color: $brand-color-dark;
            }

            h4
            {
                border-bottom: 3px solid $white;
                padding-bottom: 5px;
                display: inline-block
            }
        }

        &__block-left
        {
            width: 60%;

            &__puce
            {
                font-size: rem-calc(50);
                font-weight: 200;
            }

            &__content
            {
                font-size: rem-calc(16);
                font-weight: 200;
            }

            @include media-max("md")
            {
                width: 100%;
            }
        }

        &__block-right
        {
            position: absolute;
            right: 0;
            top: 100px;

            img
            {
                width: 480px;

                @include media-max("xl")
                {
                    width: 350px;
                }

                @include media-max("md")
                {
                    width: 300px;
                }
            }

            @include media-max("xl")
            {
                top: 260px;
            }

            @include media-max("md")
            {
                max-width: 100%;
                position: initial;
            }
        }
    }

    /*******************/
    /* FIN Comparaison */
    /*******************/

    /*********/
    /* eKomi */
    /*********/

    .ekomi
    {
        position: relative;

        &__block-left
        {
            position: absolute;
            right: calc(60%);

            img
            {
                width: 500px;
                @include media-max("lg")
                {
                    width: 200px;
                }
            }

            @include media-max("lg")
            {
                right: calc(80%);
            }
        }

        &__block-right
        {
            #title
            {
                padding: rem-calc(30) 0 rem-calc(10) 0;
                font-size: rem-calc(70);
                font-family: $font-salt;
            }

            #note
            {
                margin-top: rem-calc(20);
                margin-bottom: rem-calc(20);

                a
                {
                    font-weight: 700;
                    color: $brand-color-dark;
                    text-decoration: underline;
                }
            }

            .carousel-inner
            {
                min-height: rem-calc(180);
            }

            .carousel-item
            {
                font-size: rem-calc(22);

                blockquote
                {
                    margin: 0;
                    text-align: justify;
                    font-weight: 200;
                    @include media-max("sm")
                    {
                        font-size: rem-calc(16);
                    }
                }

                p
                {
                    font-size: rem-calc(15);
                    font-weight: 700;
                }
            }

            #rating
            {
                height: 50px;
                width: 320px;
                position: relative;
                display: inline-block;
                white-space: nowrap;

                @include media-max("sm")
                {
                    margin: 0 auto;
                    display: block;
                }
            }

            #empty-stars
            {
                direction: rtl;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                overflow: hidden;
                white-space: nowrap;
                width: 5%;

                svg
                {
                    fill: #eceff1;
                    height: 50px;
                    width: 52px;
                }
            }

            #full-stars
            {
                direction: ltr;
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
                white-space: nowrap;
                width: 95%;

                svg
                {
                    fill: #ffab00;
                    height: 50px;
                    width: 52px;

                    &:not(:last-child)
                    {
                        margin-right: 15px;
                    }
                }
            }
        }

        @include media-max("sm")
        {
            text-align: center;
        }
    }


    /*************/
    /* Fin eKomi */
    /*************/

    /**************/
    /* Parrainage */
    /**************/

    .sponsorship
    {
        background: url('../static/home/background-content-sponsorship.png') no-repeat center center transparent;
        padding-top: rem-calc(75);
        padding-bottom: rem-calc(75);

        a
        {
            color: #fff;
            display: inline-block;
            height: rem-calc(210);
            text-decoration: none;
            font-weight: $font-weight-bold;
            font-family: $font-family-brand;
            line-height: rem-calc(130);

            &:hover #sponsorship-amount
            {
                color: $brand-color-secondary;
            }

            @include media-max("lg")
            {
                line-height: rem-calc(80);
            }
        }

        &__title
        {
            text-align: left;
            position: relative;
            left: 5px;
            font-size: rem-calc(50);

            @include media-max("md")
            {
                font-size: rem-calc(30);
            }
        }

        &__amount
        {
            font-size: rem-calc(210);
            color: #fff;

            @include media-max("lg")
            {
                font-size: rem-calc(80);
            }
        }

        &__desc
        {
            font-size: rem-calc(27);

            @include media-max("md")
            {
                font-size: rem-calc(17);
            }
        }
    }

    /******************/
    /* Fin Parrainage */
    /******************/

}