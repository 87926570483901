@charset 'UTF-8';

@import
'abstracts/variables',
'abstracts/functions',
'abstracts/animation',
'abstracts/mixins';

@import
'~bootstrap-scss',
'vendors/normalize';

@import
'base/fonts',
'base/typography',
'base/base';

@import
'layout/header',
'layout/footer';

@import
'pages/home',
'pages/content',
'pages/sponsorship',
'pages/support',
'pages/partner',
'pages/recruitment',
'pages/comparator';

@import
'components/button',
'components/form';

$owl-image-path: "~owl.carousel2/src/img/";
@import "~owl.carousel2/src/scss/owl.carousel";
@import "~owl.carousel2/src/scss/owl.theme.default";
@import "~aos/dist/aos.css";
@import "~sweetalert2/dist/sweetalert2.min.css";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
@import "~mmenu-light/dist/mmenu-light.css";
@import "~mmenu-js/dist/mmenu.css";
@import "~mburger-css/dist/mburger.css";
@import "~mhead-js/dist/mhead.css";

//Main: it should ONLY contain the imports for the above files.